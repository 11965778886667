import { DateTime } from 'luxon';
export default {
  data: () => ({
    filterFromDateMenu: false,
    filterToDateMenu: false
  }),
  computed: {
    filterFromDate: {
      get() {
        return this.$store.state.statisticsTimeFilter.fromDate;
      },
      set(value) {
        this.$store.dispatch('setFilterFromDate', value);
        this.updateUrl();
      }
    },
    filterToDate: {
      get() {
        return this.$store.state.statisticsTimeFilter.toDate;
      },
      set(value) {
        this.$store.dispatch('setFilterToDate', value);
        this.updateUrl();
      }
    }
  },
  methods: {
    updateUrl() {
      if (this.filterFromDate != '' && this.filterToDate != '') this.$router.replace({
        query: {
          ...this.$route.query,
          from: this.filterFromDate,
          to: this.filterToDate
        }
      });
    }
  },
  mounted() {
    // extract 'from' date from url
    if (this.$route.query['from'] != null) {
      this.filterFromDate = this.$route.query['from'];
    } else {
      this.filterFromDate = DateTime.now().minus({
        years: 1
      }).toFormat('yyyy-MM');
    }

    // extract 'to' date from url
    if (this.$route.query['to'] != null) {
      this.filterToDate = this.$route.query['to'];
    } else {
      this.filterToDate = DateTime.now().toFormat('yyyy-MM');
    }
  }
};